<template>
    <div class="who-we-are-section">
      <div class="container">
        <h2>Who We Are</h2>
        <p>
            As a gamified mental health platform, we are committed to making mental health more engaging, interactive, and customized just for you. With PeakMind, you’ll get access to a guided path to overcome any mental health challenge. Our platform combines gamified education with evidence-based strategies to empower you to achieve your mental health goals.
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'WhoWeAre'
  }
  </script>
  
  <style scoped>
  .who-we-are-section {
    background-color: white;
    padding: 6rem 2rem 0 2rem; 
    text-align: center;
    margin-top: -50px; /* Adjust this value as needed to remove the white space */
  }
  
  .who-we-are-section .container {
    max-width: 800px;
    margin: auto;
  }
  
  .who-we-are-section h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: #08174A; 
  }
  
  .who-we-are-section p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
  }
  </style>
  
