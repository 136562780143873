<template>
  <div>
    <Header />
    <div class="header-image-section">
      <div class="container mt-5 text-center">
        <h1>Features</h1>
      </div>
    </div>
    <FeaturesSection />
    <CallToAction />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import CallToAction from '../components/CallToAction.vue'
import FeaturesSection from '../components/FeaturesSection.vue'

export default {
  name: 'Features',
  components: {
    Header,
    FeaturesSection,
    CallToAction,
    Footer
  }
}
</script>

<style scoped>
.header-image-section {
  background: url("https://i.imgur.com/HF56KSh.png") no-repeat center center;
  background-size: cover;
  padding: 100px 0;
  position: relative;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.header-image-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.0);
  z-index: 1;
}

.header-image-section .container {
  position: relative;
  z-index: 2;
}

.container {
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
}

h1 {
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 20px;
}

p {
  font-size: 22px;
  line-height: 1.6;
}
</style>
