<template>
    <div class="faq-item">
      <h3>{{ question }}</h3>
      <p>{{ answer }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FAQItem',
    props: {
      question: {
        type: String,
        required: true
      },
      answer: {
        type: String,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .faq-item {
    margin-bottom: 2rem;
  }
  
  .faq-item h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: black;
  }
  
  .faq-item p {
    font-size: 1rem;
    color: #555;
  }
  </style>
  
